import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  // ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

// import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ADD_REP_DETAILS,
  BASE_URL,
  // CREATE_CORPORATE_PROFILE,
  // VERIFY_BANK_DETAILS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import {
  // CorporateFileType,
  RepresentativeDocumentsType,
} from "../../helpers/types/Profile";
import useBreakPoints from "../../hooks/useBreakPoints";
import CustomDropdown from "../../Component/CustomDropdown";
import CustomPhoneInput from "../../Component/CustomPhoneInput";

const typeOptionsData = [
  { label: "Representative", value: "representative" },
  { label: "Director", value: "director" },
];

interface ValidationErrors {
  name: string;
  type: string;
  email: string;
  mobile: string;
  aadhaarNumber: string;
  AadhaarFront: string;
  AadhaarBack: string;
  panNumber: string;
  Pan: string;
  designation: string;
  LetterOfAuthorization: string;
}

const RepresentativeDetailModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();
  const { isTablet, isMobile } = useBreakPoints();

  const [loading, setLoading] = useState<boolean>(false);
  const [repInfo, setRepInfo] = useState({
    name: "",
    // corporationID: "",
    type: { label: "Director", value: "director" },
    email: "",
    mobile: "",
    aadhaarNumber: "",
    aadhaarFrontName: "",
    aadhaarBackName: "",
    panNumber: "",
    panFileName: "",
    designation: "",
  });
  const [fileUpload, setFileUpload] = useState<RepresentativeDocumentsType>({
    AadhaarFront: null,
    AadhaarBack: null,
    Pan: null,
    LetterOfAuthorization: null,
  });

  const [terms, setTerms] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors>({
    name: "",
    type: "",
    email: "",
    mobile: "",
    aadhaarNumber: "",
    AadhaarFront: "",
    AadhaarBack: "",
    panNumber: "",
    Pan: "",
    designation: "",
    LetterOfAuthorization: "",
  });
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const handleSubmit = () => {
    if (!terms) {
      toast.error("Please agree to the terms and conditions");
      return;
    }

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    // formData.append("corp_id", repInfo?.corporationID);
    formData.append("name", repInfo?.name);
    formData.append("mobile_number", repInfo?.mobile);
    formData.append("aadhar_number", repInfo?.aadhaarNumber);
    formData.append("pan_number", repInfo?.panNumber);
    formData.append("type", repInfo?.type?.value);
    formData.append("email", repInfo?.email);
    formData.append(
      "aadhar_front_filename",
      fileUpload.AadhaarFront && fileUpload.AadhaarFront.name
        ? fileUpload.AadhaarFront.name
        : ""
    );
    formData.append(
      "aadhar_back_filename",
      fileUpload.AadhaarBack && fileUpload.AadhaarBack.name
        ? fileUpload.AadhaarBack.name
        : ""
    );
    formData.append("pan_number", repInfo?.panNumber);
    formData.append("designation", repInfo?.designation);

    // upload files
    if (fileUpload.AadhaarFront) {
      formData.append("aadhar_front", fileUpload.AadhaarFront);
    }
    if (fileUpload.AadhaarBack) {
      formData.append("aadhar_back", fileUpload.AadhaarBack);
    }
    if (fileUpload.Pan) {
      formData.append("pan_front", fileUpload.Pan);
    }
    if (fileUpload.LetterOfAuthorization) {
      formData.append(
        "letter_resolution_authority",
        fileUpload.LetterOfAuthorization
      );
    }

    axios
      .post(BASE_URL + ADD_REP_DETAILS, formData, {
        headers: {
          Authorization: jwt,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        onClose();
        toast.success("Representative/Director Details Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error(
            "Error while submitting Representative/Director Details",
            err
          );
          toast.error(
            "Representative/Director Details Added Failed, Please try Again"
          );
        }
      });
  };

  const validateField = (name: string, value: any) => {
    let error = "";

    if (!value || (typeof value === "string" && value.trim() === "")) {
      error = "This field is required";
    } else {
      switch (name) {
        case "email":
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            error = "Invalid email address. Example: user@example.com";
          }
          break;
        case "mobile":
          const phoneDigits = value.replace(/\D/g, '');
          if (phoneDigits.length !== 10) {
            error = "Phone number must be exactly 10 digits. Example: 9876543210";
          }
          break;
        case "aadhaarNumber":
          if (!/^\d{12}$/.test(value)) {
            error = "Aadhaar number must be exactly 12 digits. Example: 123456789012";
          }
          break;
        case "panNumber":
          if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
            if (/^[a-z]{5}[0-9]{4}[a-z]{1}$/.test(value)) {
              error = "PAN Number must have capital letters. Example: ABCDE1234F";
            } else {
              error = "Invalid PAN Number format. Example: ABCDE1234F";
            }
          }
          break;
      }
    }
    return error;
  };

  const handlePhoneChange = (value: string): void => {
    const digitsOnly = value.replace(/\D/g, '').replace(/^91/, '');
    if (digitsOnly.length <= 10) {
      setRepInfo(prev => ({
        ...prev,
        mobile: digitsOnly
      }));
      const error = validateField('mobile', digitsOnly);
      setErrors(prev => ({ ...prev, mobile: error }));
      setTouched(prev => ({ ...prev, mobile: true }));
    }
  };

  const validateForm = () => {
    let formErrors: { [key: string]: string } = {};
    let hasErrors = false;

    // Validate text fields
    const fields = ["name", "email", "mobile", "aadhaarNumber", "panNumber", "designation"];
    fields.forEach(field => {
      const error = validateField(field, repInfo[field as keyof typeof repInfo]);
      if (error) {
        formErrors[field] = error;
        hasErrors = true;
      }
    });

    // Validate file uploads
    if (!fileUpload.AadhaarFront) {
      formErrors.AadhaarFront = "Aadhaar Front image is required";
      hasErrors = true;
    }
    if (!fileUpload.AadhaarBack) {
      formErrors.AadhaarBack = "Aadhaar Back image is required";
      hasErrors = true;
    }
    if (!fileUpload.Pan) {
      formErrors.Pan = "PAN Card image is required";
      hasErrors = true;
    }
    if (!fileUpload.LetterOfAuthorization) {
      formErrors.LetterOfAuthorization = "Letter of Authorization is required";
      hasErrors = true;
    }

    setErrors(prev => ({ ...prev, ...formErrors }));

    // Set all fields as touched when submitting
    const touchedFields: { [key: string]: boolean } = {};
    Object.keys(formErrors).forEach(field => {
      touchedFields[field] = true;
    });
    setTouched(prev => ({ ...prev, ...touchedFields }));

    if (hasErrors) {
      const errorMessages = Object.entries(formErrors)
        .filter(([_, error]) => error)
        .map(([field, error]) => `${field}: ${error}`);
      toast.error(errorMessages.join('\n'));
    }

    return !hasErrors;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    pdf?: boolean
  ) => {
    if (e.target.type === "file") {
      const selectedFile = e.target.files?.[0];
      if (selectedFile) {
        const allowedExtensions = [".jpeg", ".jpg", ".png"];
        const pdfExtensions = [".pdf"];
        const fileExtension = selectedFile.name.slice(
          ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        if (
          (pdf === undefined || pdf === false) &&
          allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)
        ) {
          setFileUpload((prevState) => ({
            ...fileUpload,
            [key]: selectedFile,
          }));
          setErrors(prev => ({ ...prev, [key]: "" }));
        } else if (
          pdf === true &&
          pdfExtensions.includes(`.${fileExtension.toLowerCase()}`)
        ) {
          setFileUpload((prevState) => ({
            ...fileUpload,
            [key]: selectedFile,
          }));
          setErrors(prev => ({ ...prev, [key]: "" }));
        } else {
          e.target.value = "";
          setErrors(prev => ({
            ...prev,
            [key]: pdf ? "Only PDF files are allowed" : "Only JPEG, JPG, and PNG files are allowed",
          }));
          if (pdf === true) {
            toast.error("Only PDF files are allowed");
          } else {
            toast.error("Only JPEG, JPG, and PNG files are allowed");
          }
        }
      }
    } else {
      const value = e.target.value;
      // Convert email to lowercase if it's an email field
      if (key === 'email') {
        setRepInfo(prev => ({
          ...prev,
          [key]: value.toLowerCase()
        }));
      } else {
        setRepInfo(prev => ({
          ...prev,
          [key]: value,
        }));
      }
      const error = validateField(key, value);
      setErrors(prev => ({ ...prev, [key]: error }));
    }
    setTouched(prev => ({ ...prev, [key]: true }));
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered size="xl" isOpen={open} toggle={onClose} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        ADD REPRESENTATIVE / DIRECTOR DETAILS
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row
          style={{
            backgroundColor: "#1b2431",
            borderRadius: "10px",
            margin: "10px 5px",
            padding: "0px 23px 20px 10px",
          }}
        >
          <Col className="" style={{ marginTop: 12, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>SELECT TYPE</span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Type <span style={{ color: 'red' }}>*</span></Label>
                <CustomDropdown
                  options={typeOptionsData}
                  value={repInfo.type}
                  onChange={(e: any) => {
                    setRepInfo({
                      ...repInfo,
                      type: e,
                    });
                    setErrors(prev => ({ ...prev, type: "" }));
                    setTouched(prev => ({ ...prev, type: true }));
                  }}
                  isDisabled={false}
                />
                {touched.type && errors.type && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.type}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                GENERAL INFORMATION
              </span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Name <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={repInfo.name}
                  onchange={(e: any) => handleInputChange(e, "name")}
                  placeholder="Enter Name"
                  className={`form-control ${touched.name && errors.name ? "is-invalid" : ""}`}
                  type="text"
                  required={true}
                  maxLength={100}
                />
                {touched.name && errors.name && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.name}
                  </div>
                )}
              </Col>
              <Col lg={4} md={12}>
                <Label>Designation <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={repInfo.designation}
                  onchange={(e: any) => handleInputChange(e, "designation")}
                  placeholder="Enter Designation"
                  className={`form-control ${touched.designation && errors.designation ? "is-invalid" : ""}`}
                  type="text"
                  required={true}
                  minLength={1}
                  maxLength={100}
                />
                {touched.designation && errors.designation && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.designation}
                  </div>
                )}
              </Col>
              <Col lg={4} md={12}>
                <Label>Mobile Number <span style={{ color: 'red' }}>*</span></Label>
                <CustomPhoneInput
                  label=""
                  value={repInfo.mobile}
                  onChange={handlePhoneChange}
                  error={touched.mobile && errors.mobile ? errors.mobile : ""}
                  defaultCountry="in"
                />
                {/* {touched.mobile && errors.mobile && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.mobile}
                  </div>
                )} */}
              </Col>
              <Col lg={4} md={12} className={`${isMobile ? "" : "mt-4"}`}>
                <Label>Email <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={repInfo.email}
                  onchange={(e: any) => handleInputChange(e, "email")}
                  placeholder="Enter Email"
                  className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                  type="email"
                  required={true}
                />
                {touched.email && errors.email && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.email}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>PAN DETAILS</span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>PAN Number <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={repInfo.panNumber}
                  onchange={(e: any) => handleInputChange(e, "panNumber")}
                  placeholder="Enter PAN Number"
                  className={`form-control ${touched.panNumber && errors.panNumber ? "is-invalid" : ""}`}
                  type="text"
                  required={true}
                  minLength={10}
                  maxLength={10}
                />
                {touched.panNumber && errors.panNumber && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.panNumber}
                  </div>
                )}
              </Col>
              <Col lg={4} md={12}>
                <Label>Upload PAN Card Front Page <span style={{ color: 'red' }}>*</span></Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      height: "47px",
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                    }}
                    value={fileUpload.Pan?.name}
                  />
                  <label style={{ height: 47 }} htmlFor="input-file-3">
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-3"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "Pan")
                    }
                    required
                    accept=".jpeg, .jpg, .png"
                  />
                </div>
                {touched.Pan && errors.Pan && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.Pan}
                  </div>
                )}
                {fileUpload.Pan && (
                  <div className="d-flex justify-content-center">
                    <img
                      className="mt-4"
                      height={150}
                      width={250}
                      src={URL.createObjectURL(fileUpload.Pan)}
                      alt="Uploaded File"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                AADHAAR DETAILS
              </span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Aadhaar Number <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={repInfo.aadhaarNumber}
                  onchange={(e: any) => handleInputChange(e, "aadhaarNumber")}
                  placeholder="Enter Aadhaar Number"
                  className={`form-control ${touched.aadhaarNumber && errors.aadhaarNumber ? "is-invalid" : ""}`}
                  type="text"
                  required={true}
                  minLength={12}
                  maxLength={12}
                />
                {touched.aadhaarNumber && errors.aadhaarNumber && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.aadhaarNumber}
                  </div>
                )}
              </Col>
              <Col lg={4} md={12}>
                <Label>Upload Aadhaar Card Front <span style={{ color: 'red' }}>*</span></Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      height: "47px",
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                    }}
                    value={fileUpload.AadhaarFront?.name}
                  />
                  <label style={{ height: 47 }} htmlFor="input-file-4">
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-4"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "AadhaarFront")
                    }
                    required
                    accept=".jpeg, .jpg, .png"
                  />
                </div>
                {touched.AadhaarFront && errors.AadhaarFront && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.AadhaarFront}
                  </div>
                )}
                {fileUpload.AadhaarFront && (
                  <div className="d-flex justify-content-center">
                    <img
                      className="mt-4"
                      height={150}
                      width={250}
                      src={URL.createObjectURL(fileUpload.AadhaarFront)}
                      alt="Uploaded File"
                    />
                  </div>
                )}
              </Col>
              <Col lg={4} md={12}>
                <Label>Upload Aadhaar Card Back <span style={{ color: 'red' }}>*</span></Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      height: "47px",
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                    }}
                    value={fileUpload.AadhaarBack?.name}
                  />
                  <label style={{ height: 47 }} htmlFor="input-file-5">
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-5"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "AadhaarBack")
                    }
                    required
                    accept=".jpeg, .jpg, .png"
                  />
                </div>
                {touched.AadhaarBack && errors.AadhaarBack && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.AadhaarBack}
                  </div>
                )}
                {fileUpload.AadhaarBack && (
                  <div className="d-flex justify-content-center">
                    <img
                      className="mt-4"
                      height={150}
                      width={250}
                      src={URL.createObjectURL(fileUpload.AadhaarBack)}
                      alt="Uploaded File"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{}}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                Board of Directors approved Letter of Authority
              </span>
            </div>

            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={4} md={12}>
                <Label>Upload Board of Directors approved Letter of Authority <span style={{ color: 'red' }}>*</span></Label>
                <div className="file file--upload ">
                  <Input
                    type="text"
                    className="custom-upload-file"
                    disabled
                    style={{
                      background: "#323d4e",
                      border: "#323d4e",
                      color: "white",
                      height: 47,
                    }}
                    value={fileUpload.LetterOfAuthorization?.name}
                  />

                  {fileUpload.LetterOfAuthorization && (
                    <label
                      style={{
                        background: "#3cab3d",
                        borderColor: "#3cab3d",
                        height: 47,
                        borderRadius: 0,
                      }}
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(
                            fileUpload.LetterOfAuthorization as Blob
                          ),
                          "_blank"
                        );
                      }}
                      className="preciew-btn"
                    >
                      <i className="fa fa-file-pdf-o"></i>Preview
                    </label>
                  )}

                  <label
                    style={{
                      height: 47,
                    }}
                    htmlFor="input-file-9"
                  >
                    <i className="fa fa-upload"></i>Upload
                  </label>
                  <input
                    id="input-file-9"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, "LetterOfAuthorization", true)
                    }
                    required
                    accept=".pdf"
                  />
                </div>
                {touched.LetterOfAuthorization && errors.LetterOfAuthorization && (
                  <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                    {errors.LetterOfAuthorization}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <div
        className="p-4 pt-2 d-flex justify-content-between gap-2"
        style={{ backgroundColor: "#273142" }}
      >
        <div className="d-flex align-items-center gap-3">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            value="terms"
            placeholder="terms"
            required={true}
            checked={terms}
            onChange={(e: any) => {
              setTerms(e.target.checked);
            }}
          />
          <label className="v-aligh">
            I agree to all terms and conditions.
          </label>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <CustomButton
            placeholder="Submit"
            color="#00abff"
            disabled={loading}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RepresentativeDetailModal;
