import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { VerificationModalType } from "../../helpers/types/Profile";
import { toast } from "react-toastify";
import {
  BASE_URL,
  GET_VERIFICATION_LEVEL,
  GET_VERIFICATION_LEVEL_CORP,
  SEND_OTP,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import useBreakPoints from "../../hooks/useBreakPoints";
import EmailVerificationModal from "./EmailVerificationModal";
import IdentityVerificationModal from "./IdentityVerificationModal";
import BankVerificationModal from "./BankVerificationModal";
import TwoFactorVerificationModal from "./TwoFactorVerificationModal";
import CorporateDetailModel from "./CorporateDetailModel";
import RepresentativeDetailModal from "./RepresentativeDetailModal";

// ... steps array remains unchanged ...
const steps = [
  {
    label: "Email Address",
    description: `Verify your email address.`,
  },
  {
    label: "Verify Identity",
    description: "Verify your Identity.",
  },
  {
    label: "Bank Details",
    description: `Verify bank details.`,
  },
  {
    label: "2FA Details",
    description: `Two-factor authentication`,
  },
];
const CorporateSteps = [
  {
    label: "Email Address",
    description: `Verify your email address.`,
  },
  {
    label: "2FA Details",
    description: `Two-factor authentication`,
  },
  {
    label: "Add Corporate Details",
    description: "Details of your company for approval.",
  },
  {
    label: "Add Representative / Director Details",
    description: `Details of your representative / director for approval.`,
  },
];
const VerificationStepper = () => {
  const role = GetCookies(CookieItems.ROLE);
  const { isMobile } = useBreakPoints();
  const jwt = GetCookies(CookieItems.JWT);
  const handleError = useErrorHandler();
  const navigate = useNavigate();

  const [verificationModal, setVerificationModal] =
    useState<VerificationModalType>({
      level1: false, // OTP for Email
      level2: false, // Complete Profile
      level3: false, // Verify Identity
      level4: false, // Verify Bank Details
      level5: false, // Verify Corporate Details
      level6: false, // Verify Representative Details
    });
  const [verificationLevel, setVerificationLevel] = useState<number>(-1);
  const [loading, setLoading] = useState(false);

  console.log(
    role,
    "role",
    (role === "unverified" || role === "unverified_corporate") &&
      verificationLevel === 4
  );

  useEffect(() => {
    if (jwt) {
      getVerificationLevel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, verificationModal]);

  const getVerificationLevel = () => {
    let url = role?.endsWith("corporate")
      ? GET_VERIFICATION_LEVEL_CORP
      : GET_VERIFICATION_LEVEL;

    axios
      .post(
        BASE_URL + url,
        { user_ID: -1 },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((res) => {
        setVerificationLevel(res.data.verification_level);
        // setVerificationLevel(0);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while fetching verification level", err);
          handleError(new Error("Error while fetching verification level"));
        }
      });
  };

  // HANDLE SUBMITS
  function handleOTPVerification() {
    setLoading(true);
    axios
      .post(
        BASE_URL + SEND_OTP,
        { use_ID: -1 },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((res: any) => {
        setVerificationModal((prevState) => ({
          ...prevState,
          level1: true,
        }));
        setLoading(false);
      })
      .catch((err: any) => {
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while sending OTP", err);
          handleError(new Error("Error while sending OTP"));
        }
        setLoading(false);
      });
  }

  const handleVerifyBtn = () => {
    if (verificationLevel === 0) {
      handleOTPVerification();
    } else if (verificationLevel === 1) {
      if (role?.endsWith("corporate")) {
        setVerificationModal((prevState) => ({
          ...prevState,
          level4: true,
        }));
      } else {
        setVerificationModal((prevState) => ({
          ...prevState,
          level2: true,
        }));
      }
    } else if (verificationLevel === 2) {
      if (role?.endsWith("corporate")) {
        setVerificationModal((prevState) => ({
          ...prevState,
          level5: true,
        }));
      } else {
        setVerificationModal((prevState) => ({
          ...prevState,
          level3: true,
        }));
      }
    } else if (verificationLevel === 3) {
      if (role?.endsWith("corporate")) {
        setVerificationModal((prevState) => ({
          ...prevState,
          level6: true,
        }));
      } else {
        setVerificationModal((prevState) => ({
          ...prevState,
          level4: true,
        }));
      }
    }
  };

  return (
    <div className="step-container mt-4 mb-2">
      {(role?.endsWith("corporate") ? CorporateSteps : steps)?.map(
        (step, index) => (
          <div
            className={`step ${
              verificationLevel >= index + 1 ? "completed" : ""
            }`}
          >
            {!isMobile && (
              <span
                style={{
                  height: "max-content",
                  margin: "0px 14px 0px 0px",
                  background:
                    verificationLevel >= index + 1
                      ? (role === "unverified" ||
                          role === "unverified_corporate") &&
                        index + 1 === 4
                        ? "#fd9a56"
                        : "#1ccab8"
                      : "#979797",
                  padding:
                    verificationLevel >= index + 1
                      ? (role === "unverified" ||
                          role === "unverified_corporate") &&
                        index + 1 === 4
                        ? "4px 26px"
                        : "3px 29px"
                      : "3px 38px",
                  borderRadius: "20px",
                  textWrap: "nowrap",
                  width:
                    (role === "unverified" ||
                      role === "unverified_corporate") &&
                    index + 1 === 4
                      ? "166px"
                      : "auto",
                }}
              >
                {verificationLevel >= index + 1
                  ? (role === "unverified" ||
                      role === "unverified_corporate") &&
                    index + 1 === 4
                    ? "In Progress"
                    : "completed"
                  : "Pending"}
              </span>
            )}
            <div className="v-stepper">
              <div className="circle">{index + 1}</div>
              <div className="line"></div>
            </div>

            <div className="step-content w-100">
              {isMobile && (
                <span
                  style={{
                    height: "max-content",
                    margin: "0px 14px 0px 0px",
                    background:
                      verificationLevel >= index + 1
                        ? (role === "unverified" ||
                            role === "unverified_corporate") &&
                          index + 1 === 4
                          ? "#fd9a56"
                          : "#1ccab8"
                        : "#979797",
                    padding:
                      verificationLevel >= index + 1 ? "3px 29px" : "3px 38px",
                    borderRadius: "20px",
                  }}
                >
                  {verificationLevel >= index + 1
                    ? (role === "unverified" ||
                        role === "unverified_corporate") &&
                      index + 1 === 4
                      ? "In Progress"
                      : "completed"
                    : "Pending"}
                </span>
              )}
              <div style={{ float: "left" }}>
                <p
                style={{
                  marginTop: isMobile ? "10px" : "0px",
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: 700,
                }}
                className="mb-0"
              >
                {step.label}
              </p>
              {verificationLevel >= index + 1 ? (
                <p
                  className="mb-1"
                  style={{
                    fontSize: isMobile ? "12px" : "14px",
                    color: "#bec1c6",
                  }}
                >
                  {step.description}
                </p>
              ) : (
                verificationLevel + 1 === index + 1 && (
                  <div
                    className={`${
                      isMobile
                        ? "flex-column justify-content-start align-items-start"
                        : "justify-content-between align-items-center"
                    }  d-flex   w-100`}
                  >
                    <p
                      className="mb-1"
                      style={{
                        fontSize: isMobile ? "12px" : "14px",
                        color: "#bec1c6",
                      }}
                    >
                      {step.description}
                    </p>

                        {/* <CustomButton
                      placeholder="Verify"
                      color="#00abff"
                      divclassName={`${isMobile ? "w-100" : ""}`}
                      disabled={loading}
                      style={{ width: isMobile ? "100%" : "auto" }}
                      onClick={() => {
                        handleVerifyBtn();
                      }}
                    /> */}
                      </div>

                  )
                )}
              </div>
              {verificationLevel + 1 === index + 1 && (
                <div style={{ float: "right" }}>
                  <CustomButton
                    placeholder="Verify"
                    color="#00abff"
                    divclassName={`${isMobile ? "w-100" : ""}`}
                    disabled={loading}
                    style={{ width: isMobile ? "100%" : "auto" }}
                    onClick={() => {
                      handleVerifyBtn();
                    }}
                  /></div>)}
            </div>
          </div>

        )
      )}

      {verificationModal.level1 && (
        <EmailVerificationModal
          open={verificationModal.level1}
          onClose={() => {
            setVerificationModal((prevState) => ({
              ...prevState,
              level1: false,
            }));
            getVerificationLevel();
          }}
        />
      )}
      {verificationModal.level2 && (
        <IdentityVerificationModal
          open={verificationModal.level2}
          onClose={() => {
            setVerificationModal((prevState) => ({
              ...prevState,
              level2: false,
            }));
            getVerificationLevel();
          }}
        />
      )}
      {verificationModal.level3 && (
        <BankVerificationModal
          open={verificationModal.level3}
          onClose={() => {
            setVerificationModal((prevState) => ({
              ...prevState,
              level3: false,
            }));
            getVerificationLevel();
          }}
        />
      )}

      {verificationModal.level4 && (
        <TwoFactorVerificationModal
          open={verificationModal.level4}
          onClose={() => {
            setVerificationModal((prevState) => ({
              ...prevState,
              level4: false,
            }));
            getVerificationLevel();
          }}
        />
      )}
      {verificationModal.level5 && (
        <CorporateDetailModel
          open={verificationModal.level5}
          onClose={() => {
            setVerificationModal((prevState) => ({
              ...prevState,
              level5: false,
            }));
            getVerificationLevel();
          }}
        />
      )}
      {verificationModal.level6 && (
        <RepresentativeDetailModal
          open={verificationModal.level6}
          onClose={() => {
            setVerificationModal((prevState) => ({
              ...prevState,
              level6: false,
            }));
            getVerificationLevel();
          }}
        />
      )}
    </div>
  );
};

export default VerificationStepper;
