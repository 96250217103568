import React, { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../helpers/types/contextType";
import {
  CookieItems,
  DeleteCookie,
  GetCookies,
} from "../helpers/cookies_helper";
import axios from "axios";
import { BASE_URL, VALIDATE_JWT } from "../helpers/api_helper";
import { Role } from "../helpers/types/role";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  LogoutFlag?: boolean;
  acceptedRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  LogoutFlag,
  ...rest
}) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId, handleChangeUserId } = userContext;
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const navigate = useNavigate();
  const location = useLocation();

  // Refs to track validation state
  const isValidating = useRef(false);
  const lastValidationTime = useRef(0);
  const validationTimeout = useRef<NodeJS.Timeout | null>(null);

  const clearUserData = () => {
    try {
      DeleteCookie(CookieItems.JWT);
      DeleteCookie(CookieItems.ROLE);
      const keysToRemove = ['userId', 'layout', 'resizeMode'];
      keysToRemove.forEach(key => {
        try {
          localStorage.removeItem(key);
        } catch (e) {
          console.warn(`Failed to remove ${key} from localStorage:`, e);
        }
      });
      handleChangeUserId({ userID: null, email: null, jwt: null, role: null });
    } catch (error) {
      console.warn('Error clearing user data:', error);
    }
  };

  // Safe navigation function
  const safeNavigate = (path: string) => {
    try {
      // Use window.location for critical redirects if navigate fails
      if (path === "/signin") {
        window.location.href = "/signin";
        return;
      }
      // For other navigations, try using navigate but fallback to window.location
      navigate(path, { replace: true });
    } catch (error) {
      console.warn('Navigation failed, using fallback:', error);
      window.location.href = path;
    }
  };

  // Handle routing and permissions
  useEffect(() => {
    if (LogoutFlag) {
      clearUserData();
      safeNavigate("/signin");
      return;
    }

    if (
      (userId === null || userId.jwt === null || userId.role === null) &&
      (jwt === undefined || role === undefined)
    ) {
      safeNavigate("/signin");
    } else if (
      role !== undefined &&
      rest.acceptedRoles !== undefined &&
      !rest?.acceptedRoles.includes(role as Role)
    ) {
      safeNavigate("/profile");
    }
  }, [jwt, role, userId?.jwt, userId?.role, LogoutFlag, rest.acceptedRoles]);

  // Validate JWT with debounce and cooldown
  const validateToken = async () => {
    if (isValidating.current) {
      console.log('🔄 Validation already in progress, skipping...');
      return;
    }

    const now = Date.now();
    if (now - lastValidationTime.current < 1000) {
      console.log('⏳ Validation on cooldown, skipping...');
      return;
    }

    if (jwt === undefined || role === undefined) {
      return;
    }

    try {
      isValidating.current = true;
      console.log('🔒 JWT Validation at:', new Date().toISOString());
      console.log('📍 Current path:', location.pathname);

      const response = await axios.post(BASE_URL + VALIDATE_JWT, { token: jwt });
      console.log('✅ Token validated successfully');

      lastValidationTime.current = Date.now();

      if (validationTimeout.current) {
        clearTimeout(validationTimeout.current);
      }
      validationTimeout.current = setTimeout(validateToken, 5 * 60 * 1000);

    } catch (error) {
      console.error('❌ Token validation failed:', error);
      clearUserData();
      safeNavigate("/signin");
    } finally {
      isValidating.current = false;
    }
  };

  // Setup validation on mount and major path changes
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    console.log('🔄 Setting up validation for path:', currentPath);

    const timeoutId = setTimeout(validateToken, 100);

    return () => {
      clearTimeout(timeoutId);
      if (validationTimeout.current) {
        clearTimeout(validationTimeout.current);
      }
    };
  }, [jwt, role, location.pathname]);

  if (
    (userId === null || userId.jwt === null || userId.role === null) &&
    jwt === undefined &&
    role === undefined
  ) {
    return <></>;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
