import React from "react";
import { Input } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

const CustomInputBox = ({
  type,
  value,
  onchange,
  placeholder,
  className,
  disabled,
  required,
  minLength,
  maxLength,
  style,
  allowDecimals = false,
}: {
  type: string;
  value: string;
  onchange?: (e: any) => void;
  placeholder: string;
  className: string;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
    style?: React.CSSProperties;
    allowDecimals?: boolean;
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "number") {
      // Allow: backspace, delete, tab, escape, enter, minus
      const allowedKeys = [8, 9, 27, 13, 189];

      // Add decimal point keys only if decimals are allowed
      if (allowDecimals) {
        allowedKeys.push(110, 190); // decimal point keys
      }

      if (allowedKeys.indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
        (e.ctrlKey === true && [65, 67, 86, 88].indexOf(e.keyCode) !== -1) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        return;
      }
      // Ensure that it is a number and stop the keypress if not
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      const value = e.target.value;
      // Use different regex based on allowDecimals
      const regex = allowDecimals ? /^-?\d*\.?\d*$/ : /^-?\d*$/;

      if (value === "" || regex.test(value)) {
        // Remove any non-numeric characters (and decimal if not allowed)
        const sanitizeRegex = allowDecimals ? /[^\d.-]/g : /[^\d-]/g;
        const sanitizedValue = value.replace(sanitizeRegex, '');
        e.target.value = sanitizedValue;
        onchange?.(e);
      }
    } else {
      onchange?.(e);
    }
  };

  return (
    <Input
      type={type as InputType}
      value={value}
      className={`${className} cus-input-box`}
      bsSize="lg"
      required={required || false}
      minLength={minLength || undefined}
      maxLength={maxLength || undefined}
      disabled={disabled || false}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onPaste={(e) => {
        if (type === "number") {
          const pastedData = e.clipboardData.getData('text');
          const regex = allowDecimals ? /^-?\d*\.?\d*$/ : /^-?\d*$/;
          if (!regex.test(pastedData)) {
            e.preventDefault();
          }
        }
      }}
      placeholder={placeholder}
      style={{ border: "2px solid #444e5d", ...style }}
    />
  );
};

export default CustomInputBox;
