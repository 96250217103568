import React, { useEffect, useState } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL, GET_CORPORATE_REQUEST_FILE } from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomDropdown from "../../Component/CustomDropdown";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import * as pdfjsLib from "pdfjs-dist";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const documentTypeOptions = [
  { label: "TAN", value: "tan" },
  { label: "PAN", value: "pan" },
  { label: "Memorandum of Association", value: "memorandum_o_a" },
  { label: "Articles of Association", value: "articles_o_a" },
  { label: "Certificate of Incorporation", value: "certificate_of_incorporation" },
  { label: "GST Certificate", value: "gst_certificate" },
  { label: "Cancelled Cheque", value: "cancelled_cheque" },
  { label: "Letter of Authorization", value: "letter_resolution_authority" },

];
const ViewDocumentModal = ({
  open,
  onClose,
  corporateID,
}: {
  open: boolean;
  onClose: () => void;
  requestID?: number;
  corporateStatus?: boolean;
  corporateID?: number | null;
}) => {
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [documentType, setDocumentType] = useState<{
    label: string;
    value: string;
  }>({
    label: "PAN",
    value: "pan",
  });

  console.log(open, "open");
  useEffect(() => {
    axios
      .post(
        BASE_URL + GET_CORPORATE_REQUEST_FILE,
        {
          request_id: corporateID,
          file_column_name: documentType?.value,
        },
        {
          headers: {
            Authorization: jwt,
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const newFile = new File([blob], "file.pdf", {
          type: "application/pdf",
        });
        setPdfFile(newFile);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting file", error);
          toast.error("Error while getting file " + documentType);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal centered isOpen={open} toggle={onClose}>
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        View Document
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row>
          <Col md={12} className="">
            <Label>Document View</Label>
            <CustomDropdown
              options={documentTypeOptions}
              value={documentType}
              onChange={(e: any) => setDocumentType(e)}
              isDisabled={false}
            />
          </Col>
          <Col md={12} className="mt-2">
            <div className="row modal-text-grid" style={{ height: "50vh" }}>
              {pdfFile && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer fileUrl={URL.createObjectURL(pdfFile)} />
                </Worker>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        <CustomButton
          placeholder="Close"
          color="#00abff"
          type="button"
          disabled={loading}
          onClick={() => {
            onClose();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ViewDocumentModal;
