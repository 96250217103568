// import React from 'react';
import React from "react";
import {
  Button,
  CircularProgress,
  ThemeProvider,
  createTheme,
  styled,
  Tooltip
} from "@mui/material";
import { keyframes } from "@emotion/react";

interface CustomButtonProps {
  placeholder: string;
  color: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  divclassName?: any;
  disabledText?: string;
}
const waveAnimation = keyframes`
  from {
    background-position: 0% 0;
  }
  to {
    background-position: -100% 0;
  }
`;

const CustomButton: React.FC<CustomButtonProps> = ({
  placeholder,
  color,
  onClick,
  type = "button",
  disabled,
  divclassName,
  loading,
  style,
  disabledText,
}) => {
  // Create a theme instance dynamically based on props.color
  const theme = createTheme({
    palette: {
      primary: {
        main: color,
      },
      warning: {
        main: "#3CAB3D",
      },
      info: {
        main: "#008dd2",
      },
    },
  });

  // Custom styled button to maintain opacity
  const StyledButton = styled(Button)(({ theme }) => ({
    "&.Mui-disabled": {
      opacity: loading ? 1 : 1,
      color: "#2d5d86",
      backgroundColor: "#111827",
      border: "1px solid #2d5d86",
      cursor: "not-allowed",
      position: "relative",
      "&:hover": {
        border: "1px solid #2d5d86",
        backgroundColor: "#111827",
        "& .disabled-text": {
          opacity: 1,
          visibility: "visible",
        }
      }
    },
    "&:hover": {
      backgroundImage: `linear-gradient(45deg, ${
        theme.palette.primary.main === "#008dd2" ||
        theme.palette.primary.main === "#008DD2"
          ? theme.palette.warning.main
          : theme.palette.primary.main
      }, #000b3915, #008dd2)`,
      backgroundSize: "300% 100%",
      animation: `${waveAnimation} 3s ease forwards`,
      cursor: "pointer",
      pointerEvents: "auto",
    },
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "background-color 0.5s ease, border-color 0.5s ease",
    backgroundColor:
      theme.palette.primary.main === "#008dd2" ||
      theme.palette.primary.main === "#008DD2"
        ? theme.palette.warning.main
        : theme.palette.primary.main,
    color: "#ffffff",
    overflow: "hidden",
    backgroundPosition: "-100% 0",
    border: "1px solid transparent",
    borderRadius: "6px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: "none",
    }
  }));

  const DisabledOverlay = styled('div')({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#1a2942", // Darker blue background
    color: "#ffffff", // White text for better contrast
    padding: "6px 12px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: 500,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.2s ease-in-out, visibility 0.2s ease-in-out",
    whiteSpace: "nowrap",
    pointerEvents: "none",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Subtle shadow for depth
    border: "1px solid #2d5d86", // Matching border color
    zIndex: 1, // Ensure it appears above the button text
  });

  const button = (
    <StyledButton
      variant="contained"
      color="primary"
      onClick={onClick}
      type={type}
      style={style}
      disabled={disabled || loading}
      startIcon={loading ? <CircularProgress size={24} color="info" /> : null}
    >
      {disabled && disabledText ? disabledText : placeholder}
      {disabled && <DisabledOverlay className="disabled-text">Button disabled</DisabledOverlay>}
    </StyledButton>
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        className={divclassName || ""}
        style={{ marginTop: "3px", marginLeft: "3px", marginRight: "3px" }}
      >
        {button}
      </div>
    </ThemeProvider>
  );
};

export default CustomButton;
